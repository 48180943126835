/* eslint-disable @typescript-eslint/no-this-alias */
import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import Dropdowns from "@/store/modules/dropdownModule";
import JsonExcel from "vue-json-excel";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import {
  mdiTableSearch,
  mdiCloseBox,
  mdiMinusBox,
  mdiCheckboxBlankOutline,
  mdiCalendarExport,
  mdiClose,
} from "@mdi/js";
import { Kiosko, Filtro, FormaPago } from "apd.models";
import { TableHeader } from "@/models/vuetifyModels";
import { ToDate } from "@/filters";
import { CorteReportes } from "@/models/corteReportes";
import cajerosForm from "@/components/forms/cajerosForm.vue";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);
@Component({
  components: {
    cajerosForm,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
})
export default class ReporteCortesComponent extends Vue {
  public IconTSearch: string = mdiTableSearch;
  public IconCloseCh: string = mdiCloseBox;
  public IconMinusCh: string = mdiMinusBox;
  public IconClose: string = mdiClose;
  public IconCheckbox: string = mdiCheckboxBlankOutline;
  public IconExport: string = mdiCalendarExport;

  public dialog = false;
  public tzoffset = new Date().getTimezoneOffset() * 60000;
  public date = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public date2 = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public menu = false;
  public menu2 = false;
  public mensaje = "Todos los Cajeros";
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];
  public json_fields = {
    Fecha: {
      field: "created",
      callback: (value: string): string => {
        return ToDate(value);
      },
    },
    Cajero: {
      field: "idKiosko",
      callback: (value: string): string | undefined => {
        return this.kioskoNombre(value);
      },
    },
    Referencia: "referenciaBancaria",
    SaldoInicial: {
      field: "saldoInicial",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Dotaciones: {
      field: "dotaciones",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Ingresos: {
      field: "ingresos",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Ventas: {
      field: "ventas",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Comisiones: {
      field: "comisiones",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Egresos: {
      field: "egresos",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    NoDispensados: {
      field: "cambioNoDispensado",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    SaldoFinal: {
      field: "saldoFinal",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Depositos: {
      field: "depositos",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
  };
  public headers: TableHeader[] = [
    {
      text: "Fecha",
      value: "created",
      align: "center",
      sortable: true,
    },
    { text: "Cajero", value: "kiosko", align: "center", sortable: false },
    {
      text: "Referencia",
      value: "referenciaBancaria",
      align: "center",
      sortable: false,
    },
    {
      text: "Saldo Inicial",
      value: "saldoInicial",
      align: "center",
      sortable: false,
    },
    {
      text: "Dotaciones",
      value: "dotaciones",
      align: "center",
      sortable: false,
    },
    {
      text: "Ingresos",
      value: "ingresos",
      align: "center",
      sortable: false,
    },
    {
      text: "Ventas",
      value: "ventas",
      align: "center",
      sortable: false,
    },
    {
      text: "Comisiones",
      value: "comisiones",
      align: "center",
      sortable: false,
    },
    {
      text: "Egresos",
      value: "egresos",
      align: "center",
      sortable: false,
    },
    {
      text: "No Dispensados",
      value: "cambioNoDispensado",
      align: "center",
      sortable: false,
    },
    {
      text: "Saldo Final",
      value: "saldoFinal",
      align: "center",
      sortable: false,
    },
    {
      text: "Depositos",
      value: "depositos",
      align: "center",
      sortable: false,
    },
  ];

  public formDataVista: CorteReportes[] = [];
  public cajerosArr = [] as Kiosko[];

  public formasPago: FormaPago[] = [];

  public async mounted(): Promise<void> {
    this.cajeros();
  }
  public get loading(): boolean {
    return Spinner.showLoader;
  }
  public get rules(): Rules {
    return this.$store.state.validationRules;
  }
  public get reporteNombre(): string {
    return "reporteCortes" + this.date + "/" + this.date2 + ".xls";
  }

  public cajeros(): void {
    Spinner.Show();
    //if ((Dropdowns.ListaKioskos() as Kiosko[]).length == 0) {
    Dropdowns.GetKioskos()
      .then(() => {
        this.cajerosArr = Dropdowns.ListaKioskos() as Kiosko[];
        this.cajerosArr = this.cajerosArr.filter(
          (o) =>
            o.mostrarReportes ||
            (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
        );
        this.cajerosArr.sort((a, b) => a.nombreReportes.localeCompare(b.nombreReportes));
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        Spinner.Hide();
        Snackbar.Show(err.apiError.mensajeUsuario);
      });
    Spinner.Hide();
  }

  public async buscar(formFiltro: Filtro): Promise<void> {
    Spinner.Show();

    if (formFiltro.fechaInicial) {
      this.date = formFiltro.fechaInicial;
    }

    if (formFiltro.fechaFinal) {
      this.date2 = formFiltro.fechaFinal;
    }

    await this.clienteSP()
      .ConsultarCortesCajaFiltro(formFiltro)
      .then((resp) => {
        this.formDataVista = resp as CorteReportes[];
        this.formDataVista.forEach((o) => {
          o.depositos = o.ingresos - o.egresos;
          o.cambioNoDispensado = o.cambioNoDispensado - o.cambioNoDispensadoPagado;
        });
        this.dialog = true;
        Spinner.Hide();
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        this.dialog = false;
        Spinner.Hide();
        Snackbar.Show(err.apiError.mensajeUsuario);
      });
  }

  public kioskoNombre(idKiosko: string): string | undefined {
    return (Dropdowns.ListaKioskos() as Kiosko[]).find((k) => k.id == idKiosko)?.nombreReportes;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public formDataVistaSorted() {
    return [...this.formDataVista].sort((obj1, obj2) => {
      if (Date.parse(obj1.created) > Date.parse(obj2.created)) return 1;
      if (Date.parse(obj1.created) < Date.parse(obj2.created)) return -1;
      return 0;
    });
  }
}
